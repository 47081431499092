// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/sheet.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/sheet.tsx");
  import.meta.hot.lastModified = "1728902927000";
}
// REMIX HMR END

import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva } from 'class-variance-authority';
import { X } from 'lucide-react';
import { cn } from '~/lib/utils';
const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;
const SheetPortal = ({
  className,
  ...props
}) => <SheetPrimitive.Portal className={cn(className)} {...props} />;
_c = SheetPortal;
SheetPortal.displayName = SheetPrimitive.Portal.displayName;
const SheetOverlay = React.forwardRef(_c2 = ({
  className,
  ...props
}, ref) => <SheetPrimitive.Overlay className={cn('fixed inset-0 z-50 bg-white/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 dark:bg-stone-950/80', className)} {...props} ref={ref} />);
_c3 = SheetOverlay;
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
const sheetVariants = cva('fixed z-50 gap-4 bg-white p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 dark:bg-stone-950', {
  variants: {
    side: {
      top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
      bottom: 'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
      left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
      right: 'inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm'
    }
  },
  defaultVariants: {
    side: 'right'
  }
});
const SheetContent = React.forwardRef(_c4 = ({
  side = 'right',
  className,
  children,
  ...props
}, ref) => <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({
    side
  }), className)} {...props}>
      {children}
      <SheetPrimitive.Close className='absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-stone-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-stone-100 dark:ring-offset-stone-950 dark:focus:ring-stone-300 dark:data-[state=open]:bg-stone-800'>
        <X className='h-4 w-4' />
        <span className='sr-only'>Close</span>
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPortal>);
_c5 = SheetContent;
SheetContent.displayName = SheetPrimitive.Content.displayName;
const SheetHeader = ({
  className,
  ...props
}) => <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />;
_c6 = SheetHeader;
SheetHeader.displayName = 'SheetHeader';
const SheetFooter = ({
  className,
  ...props
}) => <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />;
_c7 = SheetFooter;
SheetFooter.displayName = 'SheetFooter';
const SheetTitle = React.forwardRef(_c8 = ({
  className,
  ...props
}, ref) => <SheetPrimitive.Title ref={ref} className={cn('text-lg font-semibold text-stone-950 dark:text-stone-50', className)} {...props} />);
_c9 = SheetTitle;
SheetTitle.displayName = SheetPrimitive.Title.displayName;
const SheetDescription = React.forwardRef(_c10 = ({
  className,
  ...props
}, ref) => <SheetPrimitive.Description aria-describedby='sidebar' ref={ref} className={cn('text-sm text-stone-500 dark:text-stone-400', className)} {...props} />);
_c11 = SheetDescription;
SheetDescription.displayName = SheetPrimitive.Description.displayName;
export { Sheet, SheetTrigger, SheetClose, SheetContent, SheetHeader, SheetFooter, SheetTitle, SheetDescription };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11;
$RefreshReg$(_c, "SheetPortal");
$RefreshReg$(_c2, "SheetOverlay$React.forwardRef");
$RefreshReg$(_c3, "SheetOverlay");
$RefreshReg$(_c4, "SheetContent$React.forwardRef");
$RefreshReg$(_c5, "SheetContent");
$RefreshReg$(_c6, "SheetHeader");
$RefreshReg$(_c7, "SheetFooter");
$RefreshReg$(_c8, "SheetTitle$React.forwardRef");
$RefreshReg$(_c9, "SheetTitle");
$RefreshReg$(_c10, "SheetDescription$React.forwardRef");
$RefreshReg$(_c11, "SheetDescription");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;